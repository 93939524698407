/* -------------------------------- 

File#: _1_radios-checkboxes
Title: Radios and Checkboxes
Descr: Custom radio and checkbox buttons

-------------------------------- */

:root {
  // radios and checkboxes
  --checkbox-radio-size: 1.2em;
  --checkbox-radio-translate-y: 0.15em; // edit to align buttons with labels
  --checkbox-radio-gap: var(--space-xxxs); // gap between button and label
  --checkbox-radio-border-width: 2px;

  // radio buttons
  --radio-marker-size: 8px;

  // checkboxes
  --checkbox-marker-size: 12px;
  --checkbox-radius: 0.185em;
}
.ms-contact-page {
  margin-bottom: 0;
}
// .radio,
// .checkbox {
//   // hide native buttons
//   position: absolute;
//   left: 0;
//   top: 0;
//   margin: 0 !important;
//   padding: 0 !important;
//   opacity: 0;
//   height: 0;
//   width: 0;
//   pointer-events: none;
// }

.radio + .wpcf7-list-item-label,
.checkbox + .wpcf7-list-item-label { // label style
  display: inline-block; // fallback
  display: inline-flex;
  align-items: baseline;
  line-height: var(--body-line-height);
  user-select: none;
  cursor: pointer;
  align-items: center;
}

.radio + .wpcf7-list-item-label::before,
.checkbox + .wpcf7-list-item-label::before { // custom buttons - basic style
  content: '';
  display: inline-block;
  vertical-align: middle; // fallback
  flex-shrink: 0;
  width: var(--checkbox-radio-size);
  height: var(--checkbox-radio-size);
  background-color: var(--color-bg);
  border-width: var(--checkbox-radio-border-width);
  border-color: var(--color-contrast-low);
  border-style: solid;
  margin-right: var(--checkbox-radio-gap);
  background-repeat: no-repeat;
  background-position: center;
  transition: transform .2s, border .2s;
  margin-right: 8pt;
  margin-bottom: 4pt;
}

.radio:not(:checked):not(:focus) + .wpcf7-list-item-label:hover::before,
.checkbox:not(:checked):not(:focus) + .wpcf7-list-item-label:hover::before { // :hover
  border-color: var(--color-contrast-medium);
}

@supports (grid-area: auto) {
  .radio + .wpcf7-list-item-label::before,
  .checkbox + .wpcf7-list-item-label::before {
    position: relative;
    top: var(--checkbox-radio-translate-y);
  }
}

.radio + .wpcf7-list-item-label::before {
  border-radius: 50%; // radio button radius
}

.checkbox + .wpcf7-list-item-label::before {
  border-radius: 4px; // checkbox button radius
}

.radio:checked + .wpcf7-list-item-label::before,
.checkbox:checked + .wpcf7-list-item-label::before {
  // checked state
  background-color: var(--color-primary);
  box-shadow: none;
  border-color: var(--color-primary);
  transition: transform .2s;
}

.radio:active + .wpcf7-list-item-label::before,
.checkbox:active + .wpcf7-list-item-label::before {
  // active state
  transform: scale(0.8);
  transition: transform .2s;
}

.radio:checked:active + .wpcf7-list-item-label::before,
.checkbox:checked:active + .wpcf7-list-item-label::before {
  transform: none;
  transition: none;
}

.radio:checked + .wpcf7-list-item-label::before {
  // radio button icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--radio-marker-size);
}

.checkbox:checked + .wpcf7-list-item-label::before {
  // checkbox button icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' stroke-width='2' fill='%23ffffff' stroke='%23ffffff'%3E%3Cpolyline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='1,9 5,13 15,3 ' data-cap='butt'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--checkbox-marker-size);
}

.radio:checked:active + .wpcf7-list-item-label::before,
.checkbox:checked:active + .wpcf7-list-item-label::before,
.radio:focus + .wpcf7-list-item-label::before,
.checkbox:focus + .wpcf7-list-item-label::before {
  // focus state
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px alpha(var(--color-primary), 0.2);
}

// --radio--bg, --checkbox--bg
.radio--bg + .wpcf7-list-item-label, .checkbox--bg + .wpcf7-list-item-label {
  border-radius: .25em;
  transition: background .2s;
}

.radio--bg + .wpcf7-list-item-label:hover, .checkbox--bg + .wpcf7-list-item-label:hover {
  background-color: var(--color-contrast-lower);
}

.radio--bg:active + .wpcf7-list-item-label,
.checkbox--bg:active + .wpcf7-list-item-label,
.radio--bg:focus + .wpcf7-list-item-label,
.checkbox--bg:focus + .wpcf7-list-item-label {
  background-color: alpha(var(--color-primary), 0.1);
}

// Form Control
.form-control {
  font-size: 16px;
  border-radius: 6pt;
  min-height: 45px;
}
textarea.form-control {
  border-radius: 6pt;
  padding: 0.5em 1em 0.5em 1em;
}
.form-group {
  position: relative;
}
.form-control { 
  padding: 0.5em 1em 0.5em 1em;
  background: var(--color-bg);
  border: 1px solid var(--color-contrast-low);
  width: 100%;
  transition: border-color 150ms ease, box-shadow 150ms ease;
  &:hover {
    border-color: var(--color-primary);
  }
  &:focus {
    --color-shadow: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
    outline: none;
    border-color: var(--color-primary);
    // fix iOS 12 bug
    box-shadow: 0 0 0 4px var(--color-shadow);
    background-color: var(--color-bg);
    color: var(--color-contrast-higher);
  }

  &::-webkit-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &::-moz-placeholder {
    opacity: 1;
    color: var(--color-contrast-medium);
  }

  &:-ms-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-moz-placeholder {
    color: var(--color-contrast-medium);
  }
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    --color-shadow: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2); // fix iOS 12 bug
    box-shadow: 0 0 0 3px var(--color-shadow);
  }
}

.form-legend {
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-size: var(--text-md);
  margin-bottom: var(--space-xxs);
}

.form-label { // label style
  display: inline-block;
  font-size: var(--text-sm);
  margin-bottom: var(--space-xxxs);
}

// error message
.wpcf7-not-valid-tip {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.1);
  color: var(--color-error) !important;
  font-size: 14px !important;
  @include fontSmooth;
  padding: var(--space-xxxs) var(--space-xs);
  margin-top: var(--space-sm);
  border-radius: 6pt;

  // hide element
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);

  &::before {
    content: '';
    position: absolute;
    left: var(--space-sm);
    top: 0;
    transform: translateY(-100%);
    @include triangle(up, 4px, hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.1));
  }
}

.form-control.wpcf7-not-valid + .wpcf7-not-valid-tip {
  position: relative;
  clip: auto;
}

// radio and checkbox lists
.radio-list, .checkbox-list {
  > * {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: var(--space-xxs);
  }

  > *:last-of-type {
    margin-bottom: 0;
  }

  label {
    // radio/checkbox labels
    line-height: var(--body-line-height);
    user-select: none;
  }

  input {
    vertical-align: top;
    margin-right: var(--space-xxxs);
    flex-shrink: 0;
  }

  &.radio-list--inline, &.checkbox-list--inline {
    // inline distribution for checkboxes and radio buttons
    display: flex;
    margin-bottom: calc(-1 * var(--space-md));

    > * {
      margin: 0 var(--space-md) var(--space-md) 0;
    }
  }
}
div.wpcf7-validation-errors {
  background-color: var(--color-warning);
  font-size: 14px;
  border-radius: var(--radius-md);
  color: var(--color-bg);
}
.wpcf7 .wpcf7-response-output {
  font-size: 14px;
  border-radius: .25rem;
  background-color: var(--color-warning);
  color: var(--color-contrast-higher);
  margin: var(--space-md) 0 !important;
  border: none !important;
}
.wpcf7 .ajax-loader {
  position: absolute;
}
.post-password-form {

  p {
    margin-bottom: var(--space-sm);
  }

}
// Password
.post-password-form {
  label {
    float: left;
  }
}
input[type="password"] {
  padding: 0.5em;
  height: 45px;
  border: 1px solid var(--color-contrast-low);
  border-radius: 6pt;
  padding: 0.5em 1em 0.5em 1em;
  transition: border-color 150ms var(--ease-in-out),box-shadow 150ms var(--ease-in-out),-webkit-box-shadow 150ms var(--ease-in-out);
  
  &:hover {
    border-color: var(--color-primary);
  }
  &:focus-visible {
    --color-shadow: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
    z-index: 1;
    border-color: var(--color-primary);
    -webkit-box-shadow: 0 0 0 4px var(--color-shadow);
    box-shadow: 0 0 0 4px var(--color-shadow);
    outline: 0;
  }
}

// Contact Form 7
.ms-contact-form-7 {
  font-size: 18px;
  .btn {
    border-radius: 6pt;
    line-height: var(--body-line-height);
    transition: background-color .3s cubic-bezier(.645,.045,.355,1), transform .1s;

    &:hover {
      color: var(--color-white);
      background-color: var(--color-primary-darker);
    }
    &:active {
      transform: translateY(2px);
    }
  }
  p {
    position: relative;

    .wpcf7-spinner {
      position: absolute;
      top: 10px;
    }
  }

  .wpcf7-spinner {
    position: absolute;
  }
  input[type=submit] {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
  }
  .ms-btn__text {
    display: flex;
  }
}

// Send button
input[type=submit].wpcf7-submit {
  transition: all 250ms var(--ease-out);
  color: var(--color-white);
  background-color: var(--color-primary);
}
.wpcf7-list-item {
    display: inline-block;
    margin: 0;
    font-size: 16px;
}
.form-group, .custom-checkbox {
  padding-bottom: 24px;
}

@media screen and (max-width: 992px) {
  .ms-mc4wp--content {
    margin-bottom: 20px;
  }
}

// Fieldset Style
fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 1em;
  padding-inline-start: 1em;
  padding-inline-end: 1em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: var(--color-contrast-lower);
  border-image: initial;

  h1,h2,h3 {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 30px;
  }
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}