
:root {
  --icon-btn-fill: var(--color-contrast-medium);
}
body[data-theme="dark"] {
  --icon-btn-fill: hsl(210 15% 90%);
}

.back-to-top {
  position: fixed;
  height: 45px;
  right: 30px;
  bottom: 30px;
  z-index: 10;
  display: flex;

  .ms-btt--inner {
    display: flex;
    height: 100%;
    width: 45px;
    border-radius: 50%;
    background-color: var(--color-contrast-low);
    cursor: pointer;
    will-change: transform;
    transition: transform .1s cubic-bezier(.645,.045,.355,1), border-radius .3s cubic-bezier(.645,.045,.355,1);

    &:active {
      transform: translateY(2px);
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      transition: transform .3s cubic-bezier(.645,.045,.355,1);
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border: solid 1px;
      border-color: var(--color-contrast-low);
      position: absolute;
      border-radius: 50%;
      transform: scale(.9);
      transition: transform .3s cubic-bezier(.645,.045,.355,1), border-color .3s cubic-bezier(.645,.045,.355,1);
    }

  }

  .ms-btt-i {
    z-index: 1;
    width: 24px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    stroke: transparent;
    fill: var(--icon-btn-fill);
    transform: translateZ(0);
    transition: .3s cubic-bezier(.645,.045,.355,1);
  }

  &:hover {
    .ms-btt-i {
      fill: var(--color-contrast-lower);
    }
    .ms-btt--inner::before {
      transform: scale(1);
    }
    .ms-btt--inner::after {
      transform: scale(1.2);
      border-color: var(--color-primary);
    }
  }

}

:root, [data-theme="dark"] {
  .back-to-top {
    mix-blend-mode: normal;
  }
}

@media (max-width: 992px) {
  .back-to-top {
    right: calc(1.5rem/ 2);
    bottom: calc(1.5rem/ 2);   
  }
}

@media only screen and (max-width: 512px) {
  .back-to-top {
    display: none;
  }
}