:where(html) {
	--ease-1: cubic-bezier(.25, 0, .5, 1);
	--ease-2: cubic-bezier(.25, 0, .4, 1);
	--ease-3: cubic-bezier(.25, 0, .3, 1);
	--ease-4: cubic-bezier(.25, 0, .2, 1);
	--ease-5: cubic-bezier(.25, 0, .1, 1);
	--ease-in-1: cubic-bezier(.25, 0, 1, 1);
	--ease-in-2: cubic-bezier(.50, 0, 1, 1);
	--ease-in-3: cubic-bezier(.70, 0, 1, 1);
	--ease-in-4: cubic-bezier(.90, 0, 1, 1);
	--ease-in-5: cubic-bezier(1, 0, 1, 1);
	--ease-out-1: cubic-bezier(0, 0, .75, 1);
	--ease-out-2: cubic-bezier(0, 0, .50, 1);
	--ease-out-3: cubic-bezier(0, 0, .3, 1);
	--ease-out-4: cubic-bezier(0, 0, .1, 1);
	--ease-out-5: cubic-bezier(0, 0, 0, 1);
	--ease-in-out-1: cubic-bezier(.1, 0, .9, 1);
	--ease-in-out-2: cubic-bezier(.3, 0, .7, 1);
	--ease-in-out-3: cubic-bezier(.5, 0, .5, 1);
	--ease-in-out-4: cubic-bezier(.7, 0, .3, 1);
	--ease-in-out-5: cubic-bezier(.9, 0, .1, 1);
	--ease-elastic-1: cubic-bezier(.5, .75, .75, 1.25);
	--ease-elastic-2: cubic-bezier(.5, 1, .75, 1.25);
	--ease-elastic-3: cubic-bezier(.5, 1.25, .75, 1.25);
	--ease-elastic-4: cubic-bezier(.5, 1.5, .75, 1.25);
	--ease-elastic-5: cubic-bezier(.5, 1.75, .75, 1.25);
	--ease-squish-1: cubic-bezier(.5, -.1, .1, 1.5);
	--ease-squish-2: cubic-bezier(.5, -.3, .1, 1.5);
	--ease-squish-3: cubic-bezier(.5, -.5, .1, 1.5);
	--ease-squish-4: cubic-bezier(.5, -.7, .1, 1.5);
	--ease-squish-5: cubic-bezier(.5, -.9, .1, 1.5);
	--ease-step-1: steps(2);
	--ease-step-2: steps(3);
	--ease-step-3: steps(4);
	--ease-step-4: steps(7);
	--ease-step-5: steps(10)
}