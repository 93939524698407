.ms_theme_mode {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--space-md) + 6pt);
  transition: transform .3s cubic-bezier(.645,.045,.355,1) .1s;

  .ms_tm--inner {
    display: flex;
    align-items: center;
    width: 24px;
    justify-content: center;
  }
  
  .theme-toggle {
    --size: 22px;
    display: flex;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    inline-size: var(--size);
    block-size: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
  
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 5px;
  
    & > svg {
      inline-size: 100%;
      block-size: 100%;
      stroke-linecap: round;
      max-width: 40px;
      max-height: 40px;
      margin: auto;
    }
    
    @media (hover: none) {
      --size: 48px;
    }
  }

  .sun-and-moon {
    transition: transform .5s var(--ease-elastic-4);
    & > :is(.moon, .sun, .sun-beams) {
      transform-origin: center center;
    }
  
    & > :is(.moon, .sun) {
      fill: var(--color-contrast-high);
    }
  
    & > .sun-beams {
      stroke: var(--color-contrast-high);
      stroke-width: 2px;
  
    }
  
    body[data-theme="dark"] & {
      & > .sun {
        transform: scale(1.75);
      }
  
      & > .sun-beams {
        opacity: 0;
      }
  
      & > .moon > circle {
        transform: translateX(-7px);

      }
    }
  
    @media (prefers-reduced-motion: no-preference) {
      & > .sun {
        transition: transform .5s var(--ease-elastic-3), stroke .5s, fill .5s;
      }
  
      & > .sun-beams {
        transition: transform .5s var(--ease-elastic-4), opacity .5s var(--ease-3), stroke .5s, fill .5s;
      }
  
      & .moon > circle {
        transition: transform .25s var(--ease-out-5);
      }
  
      body[data-theme="dark"] & {
        & > .sun {
          transform: scale(1.75);
          transition-timing-function: var(--ease-3);
          transition-duration: .25s;
        }
  
        & > .sun-beams {
          transform: rotateZ(-25deg);
          transition-duration: .15s;
        }
  
        & > .moon > circle {
          transition-delay: .25s;
          transition-duration: .5s;
        }
      }
    }
  }

  .check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
  }
  &:hover {
    .sun-beams {
      transform: rotate(45deg);
      stroke: var(--color-primary);
    }
    .sun-and-moon>:is(.moon,.sun) {
      fill: var(--color-primary);
    }
  }
}

// @media only screen and (max-width: 838px) {
//   .ms_theme_mode {
//       display: none;
//   }
// }

@media (max-width: 992px) {
  .ms_theme_mode {
    right: calc(1.5rem/ 2);
    bottom: calc(1.5rem/ 2);
}
}
