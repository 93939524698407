.ms-fs-menu {
    position: fixed;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    display: flex;
    background-color: #0D0E13;
    clip-path: polygon(0 0, 100% 0, 130% 0, 0 0);
    transform: translate(0px, 0px) rotate(7deg) scale(1.3);
    transition: clip-path 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0.000, 0.995);

    .ms-fs-container {
        opacity: 0;
        transform-origin: bottom left;
        transform: translate(0px, -50%) rotate(-16deg) scale(1.3, 1.3);
        transition: transform 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), opacity 1s 0s;
        
    }

    .menu-item-has-children > a {
        display: flex;
        span {
            margin-left: 0pt;
            position: absolute;
            left: 100%;
            top: 0;
            display: flex;
            align-items: center;
            bottom: 0;
            opacity: 0;
            width: 32px;
            height: 100%;
            transition: all 320ms cubic-bezier(.575,.015,0,.995);
            svg {
                fill: var(--color-primary);
                width: 32px;
                height: 32px;
            }
        }
        &:hover span {
            opacity: 1;
            margin-left: 16pt;
        }
    }

    * .menu-item > a {
        font-family: var(--font-heading);
        cursor: pointer;
        position: relative;
        display: flex;
        color: #e8eaed;
        text-transform: uppercase;
        font-size: 48px;
        font-weight: 800;
    }

    * li {
        display: flex;
    }
    .ms-fs-container {
        position: relative;
        padding: 6rem 0;
        max-width: var(--responsive--alignwide-width);
        width: 100%;
        margin: auto auto;

        .ms-fs--nav {
            clip-path: inset( -100vw 0 -100vw 0 );
        }

        .ms-fs--contacts {
            position: relative;
            z-index: 9;
        }
    }

    ul.navbar-nav-button {
        display: block;
        list-style: none;
        width: auto;
        position: relative;
        padding-left: 0;
        transform: translateX(0%);
        // max-width: 28.125rem;

        * > ul {
            list-style: none;
            padding-left: 0;
            width: 100%;
            // max-width: 28.125rem;
        }

        > li > ul {
            position: absolute;
            top: 0;
            left: 100%;
        }

        > li > ul ul {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(100%);
        }
        .current_page_item > a {
            display: inline-block;
            border-bottom: solid 2px var(--color-primary);
        }

        .sub-menu.show  > li > a {
            display: flex;
        }

        .fl-number {
            position: absolute;
            left: -34px;
            top: 15px;
            opacity: .6;
            font-size: 16px;
        }
    }

}
.ms-fs-menu.visible {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: clip-path 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), visibility 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), opacity 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0.000, 0.995);

    .ms-fs-container {
        transform-origin: bottom left;
        opacity: 1;
        transform: translate(0px, 0px) rotate(0deg) scale(1);
        transition: opacity 1s .2s, transform 1s cubic-bezier(0.575, 0.015, 0.000, 0.995);
    }
    .menu-item {
        opacity: 1;
    }
    &::after {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        transform: translate(0px, 0px) rotate(10deg) scale(1.5);
        transition: clip-path 1s cubic-bezier(0.575, 0.015, 0.000, 0.995), transform 1s cubic-bezier(0.575, 0.015, 0.000, 0.995);
    }

}

@media screen and (max-width: 992px) {
    .ms-fs-menu {
        font-size: var(--text-xl);
    }
    .ms-fs-socials {
        display: none;
    }
}

.ms-fs-container .active {
    z-index: 9;
}

.menu-item--back {
    font-family: var(--font-heading);
    text-align: left;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 4pt;
    letter-spacing: 1pt;
    text-transform: uppercase;
    
    a {
        color: #9aa0a6;
        backface-visibility: hidden;
    }

    &::before {
    content: '';
    border: solid #9aa0a6;
    width: 12px;
    height: 12px;
    border-width: 0 3px 3px 0;
    transform: rotate(135deg);
    margin-top: 2pt;
    margin-left: 2pt;
    margin-right: 8pt;
    flex-shrink: 0;
    transition: margin-right .3s ease;
    }
    &:hover::before {
    margin-right: 16pt;
    }
    }

.navbar-nav-button.active > li {
    overflow: hidden;
}
.navbar-nav-button.active > li.loading {
    overflow: unset;
}
.ms-fs-container .navbar-nav-button > li > a,
.ms-fs-container .sub-menu  li a,
.ms-fs-container .sub-menu .sub-menu,
.ms-fs-container .sub-menu > .menu-item--back {
    opacity: 0;
    visibility: hidden;
    transition: visibility 1s, opacity .2s ease-out, color .3s ease;
}
.ms-fs-container .navbar-nav-button.active > li > a,
.ms-fs-container .sub-menu.active > li > a,
.ms-fs-container .sub-menu .sub-menu.active,
.ms-fs-container .sub-menu.active > .menu-item--back {
    opacity: 1;
    visibility: visible;
    transition: visibility 1s, opacity .2s ease-out, color .3s ease;
}

@media only screen and (max-width: 1023px) {
    .ms-fs-menu .menu-item > a {
        font-size: calc(24px + 6 * ((100vw - 20px) / 680));
    }
    .ms-fs-menu .ms-fs-container {
        width: 100%;
        padding: 4em 0;
        .ms-fs--nav  {
            padding: 0;
        }
    }
    .ms-fs-menu .menu-item-has-children>a::after {
        font-size: 22px;
        top: 10px;
    }
    .ms-fs-menu * li {
        white-space: normal;
    }
}