.portfolio-feed.ms-p--l {
    overflow: inherit !important;
    .ms-p-list {
        display: flex;
        flex-direction: column;
    }
    .ms-p-list__item {
        display: inline-flex;
        position: relative;
        width: 100%;
        height: 8.75vw;
        transition: opacity .6s var(--ease-out);

        a {
            display: flex;
            border-top: solid 1px var(--color-contrast-low);
            width: 100%;
        
            .ms-p-list-item__img-container {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                height: 70%;
                width: 0px;
                margin: auto;
                overflow: hidden;
                font-size: 0;
                transition: width .6s var(--ease-out);

                .project-list-item__image {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%) scale(1.4);
                    height: 100%;
                    width: auto;
                    margin: auto;
                    transition: transform .6s var(--ease-out);

                    .project-list-item__image {
                        max-width: fit-content;
                        backface-visibility: hidden;
                        will-change: transform;
                        transform: translateX(-50%);
                        transition: transform .6s var(--ease-out);
                    }

                }
            }

            .p-list-item__title {
                height: 70%;
                margin: auto 0;
                will-change: transform;
                transition: transform .6s var(--ease-out);

                h3 {
                    width: 30vw;
                    padding-right: 0;
                    font-size: 2vw;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }

            .p-list-item__info {
                height: 70%;
                margin: auto 0 auto 0;
                padding-top: 4pt;

                h4 {
                    will-change: transform;
                    color: var(--color-contrast-medium);
                    transition: all .6s var(--ease-out);
                    font-size: 18px;
                }

            }

            .p-list-item__icon {
                height: 70%;
                margin: auto 0 auto auto;

                svg {
                    display: block;
                    width: 18px;
                    transform: rotate(-90deg);
                    will-change: transform;
                    transition: transform .6s var(--ease-out);
                    path {
                        fill: var(--color-contrast-medium);
                        will-change: fill;
                        transition: fill .6s var(--ease-out);
                    }
                }
            }

        }

        &:hover {
            .p-list-item__icon svg {
                transform: rotate(-45deg);
                path {
                    fill: var(--color-contrast-higher);
                }
            }
        }

    }

}
@media (min-width: 1025px) {
    .ms-p-list__item {
        
        &:hover {
            .ms-p-list-item__img-container {
                width: 10.22vw !important;

                .project-list-item__image {
                    transform: translateX(-50%) translateZ(0) !important;
                    transition: transform .6s var(--ease-out);

                    .project-list-item__image {
                        transform: translateX(-50%) scale(1) translateZ(0) !important;
                        transition: transform .6s var(--ease-out);
                    }
                }
            }

            .p-list-item__title {
                transform: translateX(11.5vw);
                transition: transform .6s var(--ease-out);
            }

            .p-list-item__info h4 {
                opacity: 0;
                transform: translateX(10%);
                transition: all .5s var(--ease-out);
            }

            .p-list-item__icon svg {
                transform: rotate(-45deg);
                path {
                    fill: var(--color-contrast-higher);
                }
            }
        }

    }
}
@media (max-width: 1024px) {
    .ms-p-list__item > a {
        flex-direction: column;
        padding: 2rem 0;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__title h3 {
        width: 100%;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__icon {
        position: absolute;
        right: 0;
        top: calc(2rem + 4pt);
    }
    .ms-p-list-item__img-container {
        display: none;
    }

    .portfolio-feed.ms-p--l .ms-p-list__item,
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__title,
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__info {
        height: auto;
        margin: 0;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__info {
        display: flex;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__title h3 {
        font-size: 24px;
        margin-bottom: 1rem;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__info h4 {
        font-size: 16px;
    }
    .portfolio-feed.ms-p--l .ms-p-list__item a .p-list-item__icon svg {
        width: 14px;
        height: 14px;
    }
}