.ms-tm {
    overflow: hidden;

    .ms-tm--title {
        display: block;
        color: var(--color-contrast-higher);
    }
    .ms-tm--function,
    .ms-tm--socials a {
        color: var(--color-contrast-higher);
    }
    .ms-tm--img {
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    img {
        max-width: 100%;
        width: 100%;

    }

}

.ms-tm.style-1 {
    overflow: hidden;
    position: relative;

    .ms-tm--content {
        position: absolute;
        bottom: 0;
        padding: 20px;
        z-index: 2;
        width: 100%;
    }
    .ms-tm--title,
    .ms-tm--function,
    .ms-tm--desc {
        opacity: 0;
        transform: translateY(15px);
        transition: transform .5s var(--ease-in-out),opacity .5s var(--ease-in-out),-webkit-transform .5s var(--ease-in-out);
    }
    .ms-tm--socials {
        display: flex;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 3;
        flex-direction: column;
        align-items: center;
    }
    .ms-tm--img {
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-bottom: solid 4px var(--color-primary);

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44.5%, rgba(0, 0, 0, 0.13) 60.73%, rgba(0, 0, 0, 0.25) 80.85%, rgba(0, 0, 0, 0.5) 103.68%, #000000 120.75%);
            transition: opacity .5s var(--ease-in-out);
        }
    }
    .ms-tm--function {
        margin-top: 4pt;
        margin-bottom: 8pt;
    }
    .ms-s-icon{
        opacity: 0;
        transform: translateX(50%);
        transition: transform .3s var(--ease-in-out),opacity .3s var(--ease-in-out),-webkit-transform .3s var(--ease-in-out);
    }
    @for $i from 1 through 10 {
        .ms-s-icon:nth-child(#{$i}n) {
            transition-delay: #{$i * 0.1}s;
        }
    }
    .ms-s-icon {
        font-size: 20px;

        i {
            transition: transform 150ms var(--ease-in-out);
        }
        &:hover i {
            transform: translateY(-4px);
        }
    }
    .ms-s-icon + .ms-s-icon {
        padding-top: 4pt;
    }

    &:hover {
        .ms-tm--title,
        .ms-tm--desc,
        .ms-tm--function {
            opacity: 1;
            transform: translateX(0);
        }
        .ms-s-icon {
            opacity: 1;
            transform: translateX(0);
        }
        .ms-tm--img::after {
            opacity: 1;
        }
    }

}

.ms-tm.style-1.d_row .ms-tm--socials,
.ms-tm.style-3.d_row .ms-tm--socials {
    flex-direction: row;
    right: 40px;

    .ms-s-icon {
        transform: translateY(50%);
    }
    .ms-s-icon + .ms-s-icon {
        padding-left: 12pt;
    }
}

.ms-tm.style-3.d_row .ms-tm--socials {
    flex-direction: row;

    .ms-s-icon {
        transform: translateY(0);
    }

}

.ms-tm.style-1.d_row {

    &:hover {
        .ms-s-icon {
            transform: translateY(0);
        }
    }

}

.ms-tm.style-2 {

    .ms-tm--box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .ms-tm--img {
            width: 100%;
        }
        .ms-tm--content {
            display: inline-block;
            max-width: 100%;
            width: 100%;
            padding: 4.5% 6%;

            .ms-tm--title {
                margin-top: 20px;
            }
            .ms-tm--function {
                margin-top: 4pt;
            }
            .ms-tm--desc {
                margin-top: 8pt;
            }
        }
        .ms-tm--socials {
            max-width: 82.6%;
            position: absolute;
            bottom: 4.5%;
            left: 6%;
            padding: 8pt 12pt;
            background-color: var(--color-bg);
            border-radius: 6pt;
            opacity: 0;
            transition: opacity 520ms cubic-bezier(.575,.015,0,.995);

            .ms-s-icon {
                font-size: 20px;
                i {
                    transition: transform 150ms var(--ease-in-out);
                }
                &:hover i {
                    transform: translateY(-4px);
                }
            }

        }

        &:hover .ms-tm--socials {
            opacity: 1;
        }
    }

}

.ms-tm.style-2.center {

    .ms-tm--box {
        flex-direction: column;
        text-align: center; 

        .ms-tm--content {
            max-width: 100%;
        }

        .ms-tm--socials {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
    }

}

.ms-tm.style-2 {
    .ms-s-icon:not(:first-child) {
        padding-left: 8pt;
        margin-left: 8pt;
        border-left: solid 1px var(--color-contrast-low);
    }
}
.ms-tm.style-2.right {

    .ms-tm--box {
        flex-direction: row-reverse;
        text-align: right; 
    }
    .ms-tm--socials {
        right: 6%;
        left: auto;
    }

}

.ms-tm.style-1,
.ms-tm.style-3 {
    .ms-tm--title,
    .ms-tm--function,
    .ms-tm--socials i {
        text-shadow: 1px 1px 2px rgba(0,0,0,.3); 
    }
}

.ms-tm.style-3 {

  .ms-tm--box {
        .ms-tm--img {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44.5%, rgba(0, 0, 0, 0.13) 60.73%, rgba(0, 0, 0, 0.25) 80.85%, rgba(0, 0, 0, 0.6) 103.68%, #000000 120.75%);
                transition: opacity .5s var(--ease-in-out);
            }
        }
        .ms-tm--content {
            display: flex;
            width: 100%;
            padding: 20px;
            position: absolute;
            bottom: 0;
            z-index: 2;
            flex-direction: column;
        }
        .ms-tm--function {
            margin-top: 4pt;
            margin-bottom: 8pt;
        }
        .ms-tm--socials {
            max-width: calc(40% - 15px);
            width: 100%;
            text-align: right;
            position: absolute;
            right: 40px;
            top: 20px;
            z-index: 3;

            .ms-s-icon {
                font-size: 20px;

                i {
                    transition: transform 150ms var(--ease-in-out);
                }
                &:hover i {
                    transform: translateY(-4px);
                }
            }
        }
    }

}

.ms-tm.style-3.d_column .ms-tm--socials {
    display: flex;
    bottom: 20px;
    flex-direction: column;
}
.team-area.one{
    padding: 120px 0;
    .heading-title{
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .title{
        font-family: "Manrope", Sans-serif;
        font-size: 60px;
        font-weight: 900;
        line-height: 64px;
        margin-bottom: 50px;
        @media(max-width:1024px){
            font-size: 1.8em;
            line-height: 1.4em;
        }
    }
    .team-area-inner{
        .ms-tm{
            .ms-tm--box{
                padding: 15px;
                .ms-tm--img{
                    border-radius: 24px;
                    .ms-tm--socials{
                        .ms-s-icon{
                            color: #ffffff;
                        }
                    }
                }
                .ms-tm--content{
                    bottom: 10px;
                    .ms-tm--title{
                        font-size: 24px;
                        font-weight: 700;
                        color: #FFFFFF;
                    }
                    .ms-tm--function{
                        color: #FFFFFFD6;
                    }
                    .ms-tm--desc{
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    &.two{
        padding-top: 0;
        .ms-tm{
            .ms-tm--box{
                padding: 15px;
                .ms-tm--img{
                    border-radius: 24px;
                    .ms-tm--socials{
                        .ms-s-icon{
                            color: unset;
                        }
                    }
                }
                .ms-tm--content{
                    bottom: 10px;
                    .ms-tm--title{
                        font-size: 24px;
                        font-weight: 700;
                        color: unset;
                    }
                    .ms-tm--function{
                        color: #797979;
                    }
                    .ms-tm--desc{
                        color: unset;
                    }
                }
            }
        }
    }
    &.three{
        padding-top: 0;
        .ms-tm{
            .ms-tm--box{
                .ms-tm--content{
                    z-index: 3;
                }
            }
        }
    }
    &.gallery{
        .ms-content--portfolio{
            .blockgallery{
                .grid-item{
                    padding: 15px;
                    @media(max-width:576px){
                        width: 100%;
                    }
                    .mfp-img{
                        border-radius: 32px;
                    }
                }
            }
        }
    }
}

.contact-map-area{
    padding: 120px 0 80px 0;
    .contact-map-area-fluid{
        .contact-map{
            width: 100%;
            height: 45vh;
            -webkit-filter: grayscale(100%);
            border-radius: 24px;
        }
    }
}

.team-area.gallery1{
    padding: 120px 0 !important;
    background: #7878780D;
    .ms-content--portfolio{
        .grid-content{
            transition: all .4s;
            &:hover{
                .grid-item{
                    opacity: .5;
                }
            }
            .grid-item{
                transition: all .4s;
                width: 40%;
                @media(max-width:1024px){
                    width: 33%;
                }
                @media(max-width:768px){
                    width: 50%;
                }
                @media(max-width:576px){
                    width: 100%;
                }
                &.one{
                    width: 20%;
                    @media(max-width:768px){
                        width: 50%;
                    }
                    @media(max-width:576px){
                        width: 100%;
                    }
                    .mfp-image{
                        figure.media-wrapper--4\:3{
                            height: 100% !important;
                        }
                    }
                }
                &:hover{
                    opacity: 1;
                    .mfp-image{
                        .media-wrapper{
                            img{
                                opacity: 1 !important;
                            }
                        }
                    }
                }
                .mfp-image{
                    &:hover{
                        img{
                            opacity: 1;
                        }
                    }
                    .media-wrapper{
                        img{
                            transition: all .4s;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .blockgallery.h_s1{
        .mfp-image{
            &:hover{
                img{
                    opacity: 1;
                }
            }
        }
    }
}