@-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

$duration: 30s;

.ms-tt-wrap {
    width: 100%;
    overflow: hidden;
    box-sizing: content-box;
  
    .ms-tt {
      display: inline-block;
      white-space: nowrap;
      padding-left: 100%;
      box-sizing: content-box;
      margin-left: -100%;
  
      -webkit-animation-iteration-count: infinite; 
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
     -webkit-animation-name: ticker;
             animation-name: ticker;
      -webkit-animation-duration: $duration;
              animation-duration: $duration;
  
      &__text {
  
        display: inline-block;
        color: var(--color-contrast-higher);  
  
      }
  
    }
}

.ms-tt-wrap[data-direction="ltr"] {
    .ms-tt {
        animation-direction: reverse;
        margin-left: 100%;
    }
}
.ms-tt-wrap[data-sh="on"]:hover {
    .ms-tt {
        animation-play-state: paused;
    }
}

.ms-tt__text,
.ms-tt__text span {
  -webkit-text-stroke-color: var(--color-contrast-higher);
}