.breadcrumb-area{
    background: #2B2B2B;
    padding: 110px 0;
    position: relative;
    z-index: 2;
    &.breadcrumbs-2{
        background: #D2DCD8;
        padding: 215px 0 90px 0;
        border-radius: 0px 0px 40px 40px;
        .banner{
            @media(max-width:991px){
                align-items: center;
                text-align: center;
            }
        }
        .breadcrumb-1{
            .heading-title{
                color: #2B2B2B;
                font-family: "Manrope", Sans-serif;
                font-size: 72px;
                font-weight: 900;
                text-transform: uppercase;
                line-height: 80px;
                @media(max-width:1200px) and (min-width:991px){
                    font-size: 58px;
                    line-height: 70px;
                }
                @media(max-width:991px){
                    margin-bottom: 1em;
                }
                @media(max-width:767px){
                    font-size: 36px;
                    line-height: 1.2em;
                }
            }
        }
        .desc{
            display: flex;
            align-items: flex-end;
            align-content: flex-end;
            flex-wrap: wrap;
            text-align: right;
            color: #848987;
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            @media(max-width:991px){
                align-items: center;
                text-align: center;
            }
        }
    }
    .breadcrumb-1{
        .sub-title{
            color: #EF5D39;
            font-family: "Manrope", Sans-serif;
            font-size: 24px;
            margin-bottom: 20px;
        }
        .heading-title{
            color: #FFFFFF;
            font-family: "Manrope", Sans-serif;
            font-size: 4vw;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 1;
            @media(max-width:1024px){  
                font-size: 4em;
            }
            @media(max-width:767px){  
                font-size: 36px;
            }
        }
    }
}
.projects1.style-1{
    .breadcrumb-area.breadcrumbs-2{
        background: #EDE7E2;
    }
}